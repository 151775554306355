import React, { Fragment } from 'react';
import Masonry from 'react-masonry-component';
import Preview from '../preview';
import json from '../../data/architecture';
import './masonry.css';

export default ({ language, data }) => {
  const masonrySettings = {
    className: 'grid',
    elementType: 'div',
    options: {
      transitionDuration: 0,
      itemSelector: '.grid__item',
      percentPosition: true,
      columnWidth: '.grid__sizer',
      // gutter: 10
      // columnWidth: 160,
    }
  };

  document.title = data.meta.title[language];

  return <Fragment>
    <h1>{ data.header[language] }</h1>

    <Masonry { ...masonrySettings }>
      <div className="grid__sizer"></div>{

      json.sort((a, b) => {
        return (a.order < b.order)
          ? -1 : (a.order > b.order)
            ? 1 : 0
      })
      .reverse()
      .map((item, i) => {
        let name = item.name[language];
        let place = item.place[language];
        let year = item.year;

        let title = name + '. ' + (
          (place && year)
            ? `${place}, ${year}`
            : (place) ? place : (year) ? year : ''
        );

        let className = 'grid__item ' + (
          (item.fullWidth === true)
            ? 'grid__item_full'
            : 'grid__item_half'
          );

        let props = {
          image: `/assets/images/preview/${item.image}`,
          title: title,
          link: `/architecture/${item.url}`,
          returnUrl: '/architecture',
          language: language,
          className: className
        };

        return <Preview { ...props } key={ i } />
      })
    }</Masonry>
  </Fragment>
}
