// import React from 'react';
import Home from './modules/pages/home';
import Architecture from './modules/pages/architecture';
import ArchitectureItem from './modules/pages/architecture_item';
import Arts from './modules/pages/arts';
import Resume from './modules/pages/resume';
import Contacts from './modules/pages/contacts';
import NotFound from './modules/pages/not_found';

export default [
  {
    route: {
      exact: true,
      path: '/',
      component: Home,
    },
    data: {
      "meta": {
        "title": {
          "ru": "Берiк Молжiгiт. Персональный сайт",
          "en": "Berik Molzhigit. Personal site",
          "kk": "Берiк Молжiгiт. Жеке сайт"
        },
        "description": "",
        "keywords": []
      },
      "title": {
        "ru": "Персональный сайт",
        "en": "Personal site",
        "kk": "Жеке сайт"
      },
      "header": {
        "ru": "Новые проекты",
        "en": "New projects",
        "kk": "Жаңа жобалар"
      }
    }
  },

  {
    route: {
      exact: true,
      path: '/architecture',
      component: Architecture
    },
    data: {
      "meta": {
        "title": {
          "ru": "Архитектура",
          "en": "Architecture",
          "kk": "Сәулет"
        },
        "description": "",
        "keywords": []
      },
      "title": {
        "ru": "Архитектура",
        "en": "Architecture",
        "kk": "Сәулет"
      },
      "header": {
        "ru": "Архитектура",
        "en": "Architecture",
        "kk": "Сәулет"
      }
    }
  },

  {
    route: {
      exact: true,
      path: '/architecture/:id',
      component: ArchitectureItem
    },
    data: {}
  },

  {
    route: {
      exact: true,
      path: '/arts',
      component: Arts
    },
    data: {
      "meta": {
        "title": {
          "ru": "Живопись и графика",
          "en": "Painting and graphics",
          "kk": "Кескіндеме және графика"
        },
        "description": "",
        "keywords": []
      },
      "header": [
        {
          "ru": "Живопись и графика",
          "en": "Painting and graphics",
          "kk": "Кескіндеме және графика"
        }
      ]
    }
  },

  {
    route: {
      exact: true,
      path: '/resume',
      component: Resume
    },
    data: {
      "meta": {
        "title": {
          "ru": "Резюме",
          "en": "Resume",
          "kk": "Түйіндеме"
        },
        "description": "",
        "keywords": []
      },
      "header": {
        "ru": "Резюме",
        "en": "Resume",
        "kk": "Түйіндеме"
      }
    }
  },

  {
    route: {
      exact: true,
      path: '/contacts',
      component: Contacts
    },
    data: {
      "meta": {
        "title": {
          "ru": "Контактная информация",
          "en": "Contacts",
          "kk": "Байланыс ақпараты"
        },
        "description": "",
        "keywords": []
      },
      "header": {
        "ru": "Контакты",
        "en": "Contacts",
        "kk": "Байланыс"
      }
    }
  },

  {
    route: {
      exact: true,
      component: NotFound,
    },
    data: {
      "meta": {
        "title": {
          "ru": "Ошибка 404",
          "en": "Error 404",
          "kk": "Қате 404"
        },
        "description": "",
        "keywords": []
      },
      "header": {
        "ru": "Страница не найдена",
        "en": "Page not found",
        "kk": "Бет табылған жоқ"
      }
    }
  }
];
