import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import NotFound from './not_found';
import data from '../../data/architecture.json';
import './lightbox.css';

class ArchitectureItem extends Component {
  state = {
    isLightboxOpen: true,
    index: 0,
  }

  getLightboxSettings(item) {
    const { index } = this.state;
    const images = item.images;
    const total = images.length;

    const prefix = '/assets/images/fullsize';
    const current = images[index];

    const settings = {
      mainSrc: `${prefix}/${current}`,
      onCloseRequest: () => this.setState({ isLightboxOpen: false })
    };

    if (total > 1) {
      const next = (index + 1) % total;
      const prev = (index + total - 1) % total;

      return {
        mainSrc: `${prefix}/${current}`,
        nextSrc: `${prefix}/${images[next]}`,
        prevSrc: `${prefix}/${images[prev]}`,
        onMovePrevRequest: () => this.setState({ index: prev }),
        onMoveNextRequest: () => this.setState({ index: next }),
        onCloseRequest: () => this.setState({ isLightboxOpen: false })
      };
    }

    return settings;
  }

  getReturnUrl() {
    return (this.props.location && this.props.location.state)
      ? this.props.location.state.returnUrl : '/architecture'; // @todo parent route url
  }

  render() {
    const { match, language } = this.props;
    const { isLightboxOpen } = this.state;
    const returnUrl = this.getReturnUrl();
    const slug = match.params.id;
    const item = data.find(item => item.url === slug);

    if (! item) return <NotFound language={ language } />

    const name = item.name[language];
    const place = item.place[language];
    const year = item.year;

    const title = name + '. ' + (
      (place && year)
        ? `${place}, ${year}`
        : (place) ? place : (year) ? year : ''
    );

    document.title = title;

    return (
      (isLightboxOpen)
        ? <Lightbox { ...this.getLightboxSettings(item) } />
        : (returnUrl) ? <Redirect to={ returnUrl } /> : null
    )
  }
}
export default withRouter(ArchitectureItem);
