import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default ({ list, selected, language }) => {
  return <ul className="menu">{
    list.map((item, i) => {
      return <li className="menu__item" key={i}>{
        (selected !== item.url)
          ? <Link className="link menu__link"
                  to={ item.url }>{ item.name[language] }</Link>
          : item.name[language]
      }</li>
    })
  }</ul>
}
