import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Lightbox from 'react-image-lightbox';
import json from '../../data/resume';
import 'react-image-lightbox/style.css';
import './resume.css';

export default class Resume extends Component {
  state = {
    lightboxIsOpen: false
  }

  onClick = (e) => {
    e.preventDefault();
    this.setState({
      lightboxIsOpen: true,
      lightboxImage: e.target.src || e.target.href || e.currentTarget.href,
    });
  }

  render() {
    const { language, data } = this.props;
    const { lightboxIsOpen, lightboxImage } = this.state;

    const returnUrl = '/resume';
    const url = '/architecture'; // @todo
    const content = json[language];

    document.title = data.meta.title[language];

    return <Fragment>
      <img className="photo" src={ '/assets/'+ content.photo } width="300" alt={ content.name } />
      <h1>{ data.header[language] }</h1>

      <section>
        <h1>{ content.summary.title }</h1>
        <p>{ content.summary.content }</p>
      </section>

      <section>
        <h2>{ content.education.title }</h2>
        <dl className="time-line">
          {
            content.education.content.map((item, i) => <Fragment key={i}>
              <dt className="time-line__date">{ `${item.startDate}–${item.endDate}` }</dt>
              <dd className="time-line__text">
                <p>{ item.institution }</p>
                <p class="">{ item.faculty }</p>
                <p class="">{ item.speciality }</p>
              </dd>
            </Fragment>)
          }
        </dl>
      </section>

      <section>
        <h2>{ content.experience.title }</h2>
        <dl className="time-line">
        {
          content.experience.content.map((item, i) => <Fragment key={i}>
            <dt className="time-line__date">{ (item.endDate) ? `${item.startDate}–${item.endDate}` : '' }</dt>
            <dd className="time-line__text">{ item.position }</dd>
          </Fragment>)
        }
        </dl>
      </section>

      {
        content.projects.map((item, i) => <section key={i}>
          <h2>{ item.title }:</h2>
          <ul className="list list_dashed">
            {
              item.content.map((item, j) => <li className="list__item" key={j}>

                { item.url && <Link className="link" to={{
                  state: { returnUrl: returnUrl },
                  pathname: `${url}${item.url}`
                }}>{ item.name }</Link> }

                { ! item.url && item.name }

              </li>)
            }
          </ul>
        </section>)
      }

      <section>
        <h2>{ content.awards.title }</h2>
        <ul className="list list_dashed">
        {
          content.awards.list.map((award, i) => <Fragment key={i}>
            <li className="list__item">{ award }</li>
          </Fragment>)
        }
        </ul>

        <div className="awards">
          {
            content.awards.images
              .map((image, i) => <Fragment key={i}>
                <figure className="awards__item">
                  <a
                    href={ '/assets/' + image.src }
                    onClick={ this.onClick }>
                    <img className="awards__image"
                      src={ '/assets/' + image.src }
                      width={ image.width }
                      alt={ image.alt } />
                  </a>

                    <figcaption className="awards__caption">
                      <a className="link"
                        href={ '/assets' + image.src }
                        onClick={ this.onClick }>{ image.alt }</a>
                    </figcaption>

                </figure>
              </Fragment>)
          }
        </div>
      </section>

      <section>
        <h2>{ content.activity.title }</h2>
        <ul className="list list_dashed">
        {
          content.activity.content.map((item, i) => <Fragment key={i}>
            <li className="list__item">{ item }</li>
          </Fragment>)
        }
      </ul>
      </section>

      { lightboxIsOpen && <Lightbox
          mainSrc={ lightboxImage }
          onCloseRequest={ () => this.setState({ lightboxIsOpen: false }) }/> }

    </Fragment>
  }
}
