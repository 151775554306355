import React, { Component, Fragment } from 'react';

export default class LanguageSwitch extends Component {
  state = {
    value: null
  }

  onChange(value) {
    this.setState({ value: value });
  }

  componentWillMount() {
    this.setState({ value: this.props.language });
  }

  render() {
    const { list, onChange } = this.props;
    const codes = Object.keys(list);

    return <Fragment>
      <select
        id="lang-switch"
        className="lang-switch"
        onChange={ (e) => {
          this.onChange(e.target.value)
          onChange(e.target.value)
        }}
        value={ this.state.value }
        >
      { codes.map((code, i) => {
          let isSelected = this.state.value === code;
          let className = 'lang-switch__item' +
            ((isSelected) ? ' lang-switch__item_active' : '');

          return <option
            key={ i }
            className={ className }
            value={ code }
          >{ list[code] }</option>
      }) }
      </select>
    </Fragment>
  }
}
// value={ language }
// selected={ isSelected }
// defaultValue={ (isSelected) ? code : null }
