import React, { Component, Fragment } from 'react';
// import { Redirect } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import Preview from '../preview';
import json from '../../data/architecture';
import './slider.css';

// const Preview = ({ image, title, link, returnUrl, language, onClick, className = ''}) =>
//   <figure className={ 'preview ' + className }>
//     {
//       <Link
//         className="preview__link"
//         to={{
//           state: { returnUrl: returnUrl },
//           pathname: link
//         }}>
//         <img
//           className="preview__image"
//           src={ image }
//           alt={ title } />
//       </Link>
//     }
//     <figcaption className="preview__title">{ title }</figcaption>
//   </figure>
// };

export default class Home extends Component {
  state = {
    isDragging: false
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.isDragging === nextState.isDragging;
  }

  onClick = (e) => {
    if (this.state.isDragging) {
      e.preventDefault();
    }
  }

  render() {
    const { language, data } = this.props;
    const list = json.slice(0, 7);

    document.title = data.meta.title[language];

    return <Fragment>
      <h1>{ data.header[language] }</h1>

      <Carousel
        showArrows={ true }
        showStatus={ false }
        showThumbs={ false }

        infiniteLoop={ true }

        swipeable={ true }
        emulateTouch={ true }

        transitionTime={ 350 }

        autoPlay={ true }
        interval={ 3000 }
        stopOnHover={ true }

        useKeyboardArrows={ true }

        onClickItem={index => {
          let url = `/architecture/${list[index].url}`
          window.history.pushState({
            state: { returnUrl: '/' }
          }, null, url);
          window.location = url;
        }}
      >{
        // json.slice(0, 7).map((item, i) => {
        list.map((item, i) => {

          let name = item.name[language];
          let place = item.place[language];
          let year = item.year;

          let title = name + '. ' + (
            (place && year)
              ? `${place}, ${year}`
              : (place) ? place : (year) ? year : ''
          );


          let image = `/assets/images/preview/${item.image}`;
          // let link = `/architecture/${item.url}`;
          // let returnUrl = '/';


          return <figure className={ 'preview ' } key={ i }>
              {/* <Link
                className="preview__link"
                to={{
                  state: { returnUrl: returnUrl },
                  pathname: link
                }}
                onClick={ this.onClick }> */}
                <img
                  className="preview__image"
                  src={ image }
                  alt={ title } />
              {/* </Link> */}
            <figcaption className="preview__title">{ title }</figcaption>
          </figure>

        })
      }</Carousel>
    </Fragment>
  }
}
