import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

export default ({ image, title, link, returnUrl, language, onClick, className = ''}) =>
  <figure className={ 'preview ' + className }>
    {
      (onClick)
        ? <img
            className="preview__image"
            src={ image }
            alt={ title }
            onClick={ onClick } />
        : <Link
            className="preview__link"
            to={{
              state: { returnUrl: returnUrl },
              pathname: link
            }}>
            <img
              className="preview__image"
              src={ image }
              alt={ title } />
          </Link>
    }
    <figcaption className="preview__title">{ title }</figcaption>
  </figure>
