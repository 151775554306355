import React, { Component } from 'react';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import Menu from './modules/menu';
import LanguageSwitch from './modules/language-switch';
import routes from './routes.js'
import menu from './data/menu.json';
import languages from './data/languages.json';
import siteName from './data/site-name.json'; // @todo
import './App.css';

class App extends Component {
  componentWillMount() {
    const { language } = this.props;
    this.setState({ language: language });
  }

  onLanguageSwitch = (language) => {
    this.setState({ language: language });
  }

  render() {
    const { language } = this.state;

    // console.log(this.props.location)

    document.title = siteName[language];
    document.documentElement.lang = language;

    return <div className="page">

        <header className="page__header">
          <Link className="logo" to="/">{ siteName[language] }</Link>

          <LanguageSwitch
            list={ languages }
            language={ language }
            onChange={ this.onLanguageSwitch.bind(this) }
          />
          <nav className="page__menu">
            <Menu
              list={ menu }
              selected={ this.props.location.pathname }
              language={ language } />
          </nav>
        </header>

        <main className="page__content">
          <Switch>{
            routes.map((item, i) => <Route
              key={ i }
              exact={ item.route.exact }
              path={ item.route.path }
              component={ data => <item.route.component
                language={ language }
                data={ item.data }
              /> }
            />)
          }</Switch>
        </main>

        <footer className="page__footer">
          { new Date().getFullYear() } © { siteName[language] }
        </footer>

      </div>
  }
}
export default withRouter(App);
