import React, { Component, Fragment } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import json from '../../data/contacts';
import './contacts.css';

export default class Contacts extends Component {
  state = {
    lightboxIsOpen: false
  }

  onClick = (e) => {
    e.preventDefault();
    this.setState({
      lightboxIsOpen: true,
      lightboxImage: e.target.href,
    });
  }

  render() {
    const { language, data } = this.props;
    const { lightboxIsOpen, lightboxImage } = this.state;

    const path = '/assets';
    const content = json[language];
    const mapTitles = { ru: 'Карта', en: 'Map', kk: 'Карта' }; // @todo

    document.title = data.meta.title[language];

    return <div className="contacts" itemScope itemType="http://schema.org/Organization">
      <h1>{ data.header[language] }</h1>

      <section className="contacts__org">
        <div itemProp="name">{ content.name }</div>
        {
          content.documents.map((doc, i) => <Fragment key={i}>
            <a className="link"
               href={ `${path}/${doc.url}` }
               onClick={ this.onClick }>{ doc.name }</a> { doc.no }<br />
          </Fragment>)
        }
      </section>

      <section className="contacts__address">
        <h2>{ content.address.title }</h2>
        <address itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <span
            itemProp="postalCode">{ content.address.postalCode }</span>, <span
            itemProp="addressLocality">{ content.address.addressLocality }</span>, <span
            itemProp="streetAddress">{ content.address.streetAddress }</span>
        </address>

        <iframe className="contacts__map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A501d063f9e27bc0168c2ac36b09d228f7c3b455671d3b2235b29b4881f28fbb2&amp;source=constructor"
        width="500" height="380" frameBorder="0" title={ mapTitles[language] }></iframe>
      </section>

      <section className="contacts__phones">
        <h2>{ content.phones.title }</h2>
        {
          content.phones.list.map((phone, i) => <Fragment key={i}>
            <a className="link"
               href={ "tel:" + phone.replace(/[^\\+\d]/g, '') }
               itemProp="telephone">{ phone }</a><br />
          </Fragment>)
        }
      </section>

      <section className="contacts__email">
        <h2>{ content.email.title }</h2>
        {
          content.email.list.map((email, i) => <Fragment key={i}>
            <a className="link"
               href={ "mailto:" + email }
               itemProp="email">{ email }</a><br />
          </Fragment>)
        }
      </section>

      { lightboxIsOpen && <Lightbox
          mainSrc={ lightboxImage }
          onCloseRequest={ () => this.setState({ lightboxIsOpen: false }) }/> }
    </div>
  }
}
