import React from 'react';

// @todo
const defaultProps = {
  "meta": {
    "title": {
      "ru": "Ошибка 404",
      "en": "Error 404",
      "kk": "Қате 404"
    },
    "description": "",
    "keywords": []
  },
  "header": {
    "ru": "Страница не найдена",
    "en": "Page not found",
    "kk": "Бет табылған жоқ"
  }
};

export default ({ language, data }) => {
  if (! data) data = defaultProps;
  document.title = data.meta.title[language];
  return <h1>{ data.header[language] }</h1>
}
