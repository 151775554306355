import React, { Component, Fragment } from 'react';
import Masonry from 'react-masonry-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Preview from '../preview';
import json from '../../data/arts.json';
import './masonry.css';

export default class Arts extends Component {
  state = {
    lightboxIsOpen: false,
    lightboxImage: null
  }

  constructor(props) {
    super(props);
    this.state = { show: false };
  }
  // hide() {
  // }
  // show() {
  // }
  // componentDidMount() {
  //   this.hide();
  //   // console.log(this.style);
  // }

  onClick(image) {
    this.setState({ lightboxIsOpen: true });
    this.setState({ lightboxImage: image });
  }

  getMasonrySettings() {
    return {
      className: 'grid',
      elementType: 'div',
      options: {
        transitionDuration: 0,
        itemSelector: '.grid__item',
        percentPosition: true,
        columnWidth: '.grid__sizer',
        // gutter: 10
        // columnWidth: 160,
      },


      // updateOnEachImageLoad: true,

      imagesLoadedOptions: { background: '.my-bg-image-el' },

      onLayoutComplete: () => {
        // console.log('onLayoutComplete');
      },

      onImagesLoaded: () => {
        // console.log('onImagesLoaded');
       // this.setState({ show: false })
      }
    };
  }

  getLightboxSettings() {
    return {
      mainSrc: this.state.lightboxImage,
      onCloseRequest: () => this.setState({ lightboxIsOpen: false })
    };
  }

  render() {
    const { language, data } = this.props;
    const { lightboxIsOpen } = this.state;
    const years = { ru: 'г.', en: '', kk: 'ж.' }; // @todo

    let previewPath = '/assets/pictures/preview';
    let fullsizePath = '/assets/pictures/fullsize';

    document.title = data.meta.title[language];

    return <Fragment> {
      json.map((section, i) => {
        return <Fragment key={ i }>
          <h1>{ section.title[language] }</h1>

           <Masonry { ...this.getMasonrySettings() }>
            <div className="grid__sizer"></div>{
              section.list

                .map((item, j) => {
                  let preview = `${previewPath}/${item.src}`;
                  let fullsize = `${fullsizePath}/${item.src}`;

                  let { title, material, size, year } = item;

                  let className = 'grid__item ' + (
                    (item.fullWidth === true)
                      ? 'grid__item_full'
                      : 'grid__item_half'
                    );

                  let props = {
                    image: preview,
                    title: `${title[language]} ${material[language]} ${size}. ${year} ${years[language]}`,
                    returnUrl: '/',
                    language: language,
                    className: className,
                    onClick: this.onClick.bind(this, fullsize)
                  };

                  return <Preview { ...props } key={ j } />
                })
          }</Masonry>
        </Fragment>
      })}

      { lightboxIsOpen && <Lightbox { ...this.getLightboxSettings() } /> }

    </Fragment>
  }
}
